.drawer-paper {
  top: 64px;
  border: none;
  box-shadow: none;
  width: 100%;
  max-width: 400px;
}

.wrapper {
  height: calc(100vh - 64px);
  border-left: 1px solid #e7e8ec;
  width: 100%;
  max-width: 400px;
}

.bar {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e8ec;
}

.section-count {
  display: flex;
}

.margin-right {
  margin-right: 8px;
}

.section-buttons {
 display: flex;
 flex-wrap: nowrap;
}

.tooltip {
  background: #0b1641;
}

.tooltip-arrow {
  color: #0b1641;
}

.close-button path {
  stroke: #0b1641;
}

.close-button:hover,
.button:hover {
  background: #f5f6f8 !important;
  border-radius: 0;
}

.notices {
  height: calc(100vh - 128px);
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-width: thin;
}