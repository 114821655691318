.wrapper {
  position: relative;
}

.wrapper .color-picker {
  position: absolute;
  bottom: 0;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e7e8ec;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  width: fit-content;
}

.wrapper .react-colorful {
  width: fit-content;
}

.wrapper .react-colorful__saturation-pointer {
  width: 12px;
  height: 12px;
}

.wrapper .react-colorful__pointer-fill {
  background: #333 !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
}

.wrapper .react-colorful__hue {
  width: 240px;
  border-radius: 7px;
  margin-bottom: 18px;
}

.wrapper .react-colorful__hue-pointer {
  height: 18px;
  width: 18px;
  background: #333;
}

.wrapper .react-colorful__saturation {
  width: 240px;
  height: 240px;
  border: none;
  border-radius: 0%;
  margin-bottom: 16px;
}

.wrapper .color-box {
  cursor: pointer;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  border: 1px solid #e7e8ec;
}

.wrapper .flex-container {
  display: flex;
}

.wrapper .input {
  box-sizing: border-box;
  height: 40px;
  width: 171px;
  border: 1px solid #e7e8ec;
  border-radius: 1px;
  font-family: Inter;
  color: #3c4567;
  padding-left: 16px;
}

.wrapper .input-root {
  margin-left: auto;
}

.wrapper .input:hover {
  border-color: #858ba0;
}

.wrapper .input:focus {
  border-color: #606783;
  border-width: 2px;
  padding-left: 15px;
}

.wrapper .input-root::before,
.wrapper .input-root::after {
  display: none;
}

.wrapper .text-field {
  margin-left: 24px;
}

.wrapper .color-picker .text-field {
  margin-left: auto;
}
