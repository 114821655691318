.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  transition: 0.5s ease all;
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}

.menu-open {
  width: calc(100% - 280px);
  margin-left: 280px;
  transition: 0.5s ease all;
}

@media screen and (max-width: 1023px) {
  .menu-open {
    width: 100%;
    margin-left: 0;
  }
}
