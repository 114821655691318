.input-tooltip {
  background: #0b1641;
}

.input-tooltip-arrow {
  color: #0b1641;
}

.input-standart {
  position: absolute;
  right: 20px
}

.input-adornment-button:hover, .input-standart:hover{
  background: none !important;
}

.input-adornment-button:hover path, .input-standart:hover path {
  stroke: #0b1641;
}
