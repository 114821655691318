.checkbox {
  padding: 0;
}

.checkbox path:first-child {
  fill: #4c6ad4;
}

.checkbox:hover {
  background: none;
}

.checkbox-disabled path:first-of-type {
  fill: #ecedf0;
}

.indeterminate::after {
  content: '';
  display: block;
  width: 8px;
  height: 2px;
  background: #4c6ad4;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
