.drawer-paper {
  top: 213px;
  width: 100%;
  max-width: 400px;
  border: none;
  box-shadow: none;
}
  
.wrapper {
  height: calc(100vh - 213px);
  width: 100%;
  max-width: 400px;
  border-left: 1px solid #e7e8ec;
}

.header {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  box-sizing: border-box;
  border-bottom: 1px solid #e7e8ec;
}

.close-button path {
  stroke: #0b1641;
}

.close-button:hover,
.button:hover {
  background: #f5f6f8 !important;
  border-radius: 0;
}

.filter-form {
  padding: 24px;
  height: calc(100vh - 383px);
  overflow-y: auto;
  overflow-x: hidden;
}

.input-title {
  margin: 32px 0 8px;
}

.bottom-buttons {
  padding: 24px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 88px;
  border-top: 1px solid #e7e8ec;
}

.count {
  margin: 0 0 0 8px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FC545C;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
}

.menu {
  width: 157px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 0;
}

.menu ul {
  padding: 0;
  border-radius: 0;
}

.select {
  height: 40px;
  width: 100%;
  border-radius: 0;
}

.select + .select {
  margin-left: 32px;
}

.select svg {
  top: calc(50% - 12px);
  right: 12px;
}

.select fieldset {
  border-color: #e7e8ec;
}

.select-item {
  padding-left: 55px;
  display: block;
  position: relative;
  color: #3c4567;
}

.check-icon {
  display: none;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.select-item-selected {
  background-color: #f5f6f8 !important;
}

.select-item-selected .check-icon {
  display: block;
}

.outlined {
  padding: 10px 14px;
  color: #3c4567
}

.calendar-bar {
  padding: 0 24px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker {
  margin-bottom: 8px;
  padding-left: 16px;
  width: 100%;
  height: 40px;
  border: 1px solid #e7e8ec;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3c4567;
}

.date-picker__error {
  border-color: #fc545c;
}

.date-picker__error:focus {
  border-color: #fc545c !important;
  border-width: 2px !important;
}

.error-message {
  color: #d32f2f;
  font-size: 14px;
  line-height: 21px;
}