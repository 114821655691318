.radio-icon {
  margin-right: 16px;
}

.radio-button {
  text-align: left;
}

.container .radio-button {
  padding: 0;
}

.container .radio-button:hover {
  background: none;
}
