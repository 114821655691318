.wrapper {
  padding: 32px 0 0 32px;
  width: 100%;
  height: calc(100vh - 64px);
}

.bar {
  padding: 0 32px 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-top: 1px solid #e7e8ec;
  border-bottom: 1px solid #e7e8ec;
}

.search {
  margin-right: 16px;
  min-width: 120px;
  width: 400px;
  height: 40px;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.input {
  padding-left: 48px !important;
}

.action-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
}

.menu {
  width: 157px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 0;
}

.menu ul {
  padding: 0;
  border-radius: 0;
}

.select {
  margin-right: 16px;
  width: 200px;
  height: 40px;
  border-radius: 0;
}

.select + .select {
  margin-left: 32px;
}

.select svg {
  top: calc(50% - 12px);
  right: 12px;
}

.select fieldset {
  border-color: #e7e8ec;
}

.select-item {
  padding-left: 55px;
  display: block;
  position: relative;
}

.check-icon {
  display: none;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.select-item-selected {
  background-color: #f5f6f8 !important;
}

.select-item-selected .check-icon {
  display: block;
}

.outlined {
  padding: 10px 14px;
}

.tooltip {
  background: #0b1641;
}

.tooltip-arrow {
  color: #0b1641;
}

.download-button {
  margin-right: 16px;
}

.filter-button {
  position: relative;
  margin-right: 20px;
  padding: 0;
  width: 40px;
  height: 40px;
  background: #ecedf0;
  border-radius: 0;
}

.filter-button:hover {
  background-color: #e7e8ec !important;
}

.filter-count {
  position: absolute;
  background: #ff615e;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 5%;
  right: 5%;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-popover-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  border-radius: 1px;
  width: 372px;
  height: 186px;
}

.view {
  padding-right: 0;
  height: 56px;
}

.view:hover {
  background: none;
}

.view-icon {
  margin-left: 4px;
  margin-right: 0;
}

.content {
  position: relative;
  width: 100%;
  height: calc(100vh - 213px);
}

.log-table {
  width: calc(100% - 549px);
  height: calc(100vh - 213px);
  transition: 0.5s ease all;
}

.full-width {
  transition: 0.5s ease all;
  width: 100%;
}

.log-table-wrapper {
  height: 100%;
}

.animate-width,
.animate-width div {
  transition: 0.5s ease all;
}

.table-row {
  padding-left: 24px;
  border-bottom: 1px solid #e7e8ec;
  cursor: pointer;
}

.content-row {
  padding-right: 0 !important;
  width: 100% !important;
}

.content-row:hover {
  background: #fbfbfc;
}

.selected-row {
  background: #f5f6f8;
}

.selected-row:hover {
  background: #f1f1f4;
}

.table-cell {
  padding: 0;
  flex: 1;
  height: 56px;
  border: none;
}

.table-cell .open-actions-icon:hover,
.active-actions-icon {
  background: #e7e8ec;
}

.table-cell .open-actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.flex-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.header-cell {
  padding: 0;
  height: 56px;
  white-space: nowrap;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.header-button:hover {
  background: none !important;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rotate-180 {
  transform: rotate(180deg);
}

.event-avatar {
  margin-right: 16px;
  min-width: 24px;
}

.empty {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-inner {
  position: relative;
}

.empty-icon {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  width: 140px;
  height: 140px;
}

@media screen and (max-width: 1023px) {
  .bar {
    padding: 0;
  }

  .table-row {
    padding-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .search {
    margin-right: 8px;
  }

  .input {
    padding-left: 28px !important;
  }

  .download-button {
    margin-right: 8px;
  }

  .select {
    margin-right: 8px;
    width: 100px;
  }

  .check-icon {
    left: 5px;
    top: 35%;
  }

  .select-item {
    padding-left: 35px;
  }
}
