.text-12 {
  font-size: 12px;
  line-height: 16px;
}

.text-15-medium {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.text-14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.text-20 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.header-2-medium {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.header-3 {
  font-size: 17px;
  line-height: 24px;
}

.text-24-medium {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.text-24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.text-15-regular {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.text-17-regular {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}

.font-golos {
  font-family: Golos;
}

.font-basically {
  font-family: BasicallyASansSerif;
}

.sf-16-reg {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.color-FC545C {
  color: #fc545c;
}

.color-858BA0 {
  color: #858ba0;
}

.color-0B1641 {
  color: #0b1641;
}

.color-4C6AD4 {
  color: #4c6ad4;
}

.color-FFF {
  color: #fff;
}

.color-B6BAC6 {
  color: #b6bac6;
}

.color-3C4567 {
  color: #3C4567;
}