.widget-color {
  padding-bottom: 40px;
}

.set-color + .set-color {
  margin-top: 32px;
}

.color-title {
  margin-bottom: 8px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.widget-wrapper {
  padding: 16px 24px 24px 24px;
  background: #f5f6f8;
}

.widget {
  background: #fff;
  width: 395px;
  padding: 20px 24px 24px 24px;
}

.widget-title {
  font-size: 12px;
  line-height: 14px;
  color: #9da2b3;
  margin-bottom: 20px;
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  width: 100%;
}

.widget-subtitle {
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 22px;
  line-height: 30px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.divider {
  width: calc(100% + 48px);
  height: 1px;
  background: #e7e8ec;
  margin-left: -24px;
  margin-bottom: 45px;
}

.text-field {
  min-height: 88;
  color: rgb(51, 51, 51);
}

.widget-input-label {
  border: none;
  color: rgb(51, 51, 51);
}

.widget-input-label-focused label {
  color: rgb(51, 51, 51);
  opacity: 0.38;
}

.input-underline:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.disable-asterisk {
  display: none;
}

.widget-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 72px;
}

.preview {
  margin-bottom: 16px;
}

.color-error {
  color: #fc545c;
  margin-top: 8px;
}

@media screen and (max-width: 1023px) {
  .flex-container {
    display: block;
  }

  .widget {
    width: auto;
    max-width: 396px;
  }

  .widget-color {
    padding-bottom: 32px;
  }

  .widget-wrapper {
    margin-top: 24px;
    padding: 16px 16px 24px 16px;
  }
}
