.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
  height: fit-content;
}

.button-back {
  padding: 0;
}

.button-back:hover {
  background: none;
}

.input {
  padding-left: 48px !important;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: #4c6ad4;
}

.profile-provider {
  width: 600px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.provider {
  display: flex;
  align-items: center;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
  margin: 16px 0;
  cursor: pointer;
}

.provider:hover {
  background: #fbfbfc;
}

.provider-icon-wrapper {
  border-radius: 4px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border: 1px solid #e7e8ec;
  background-size: cover;
  background-repeat: no-repeat;
}