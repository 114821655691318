.backdrop {
  background: none;
}

.drawer-paper {
  box-shadow: none;
}

.wrapper {
  background: #fff;
  padding: 27px 0 0 0;
  width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 32px;
}

.create-provider-form {
  height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 32px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: #4c6ad4;
}

.button-back {
  padding: 0;
  margin-right: 16px;
}

.paste-provider-button {
  margin-left: auto;
  padding: 0;
}

.paste-provider-button:hover path {
  fill: #0b1641;
}

.close-button {
  margin-left: 24px;
  padding: 0;
}

.close-button path {
  stroke: #0b1641;
}

.subtitle {
  margin-bottom: 24px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.input-title {
  margin-bottom: 8px;
}

.input-subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.input-error {
  color: #fc545c;
  margin-bottom: 8px;
}

.id-icon path {
  fill: #ced0d9;
  stroke: #ced0d9;
}

.id-icon path:nth-child(1) {
  stroke: none;
}

.id-icon path:nth-child(3) {
  fill: none;
}

.divider {
  width: calc(100% + 64px);
  height: 1px;
  margin-left: -32px;
  margin-bottom: 32px;
  background: #e7e8ec;
}

.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0 32px;
}

.select {
  width: 280px;
  border: 1px solid #e7e8ec;
  border-radius: 1px;
  background: #fff;
  height: 40px;
  position: relative;
  flex-grow: 1;
}

.select::before,
.endpoint-address::before {
  position: absolute;
  content: 'Endpoint name';
  color: #0b1641;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  top: -40px;
  left: 0;
}

.endpoint-address::before {
  content: 'Адрес';
}

.endpoints-helper {
  position: absolute;
  color: #d32f2f;
  top: 37px;
  font-family: Inter;
  font-size: 14px;
  line-height: 21px;
}

.select:hover {
  border-color: #858ba0;
}

.select fieldset {
  display: none;
}

.select-icon {
  fill: #858ba0;
  opacity: 0.5;
}

.select-icon-open {
  transform: rotate(0);
}

.endpoint-address {
  flex-grow: 1;
  position: relative;
}

.endpoint-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.endpoint-item + .endpoint-item {
  margin-top: 60px;
}

.input-tooltip {
  background: #0b1641;
}

.input-tooltip-arrow {
  color: #0b1641;
}

.input-adornment-button:hover {
  background: none !important;
}

.input-adornment-button:hover path {
  stroke: #0b1641;
}

.switch-root {
  margin-left: auto;
}

.select-wrapper {
  position: relative;
}

.endpoint-item-helper {
  margin-bottom: 45px;
}

.endpoint-item + .endpoint-item-helper {
  margin-top: 80px;
}

.save-modal {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.switch-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}
