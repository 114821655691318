.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
  height: fit-content;
}

.button-back {
  padding: 0;
}

.wrapper .button-back:hover {
  background: none;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: #4c6ad4;
}

.delete-account {
  width: 600px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.input-tooltip {
  background: #0b1641;
}

.input-tooltip-arrow {
  color: #0b1641;
}

.input-adornment-button:hover {
  background: none !important;
}

.input-adornment-button:hover path {
  stroke: #0b1641;
}

.app-icon {
  background: #fff;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 32px;
}

.app {
  display: flex;
  align-items: center;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  padding: 12px 16px;
}

.app + .app {
  margin-top: 16px;
}

.app-link {
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #4c6ad4;
}

.app-link:hover {
  text-decoration: underline;
}

.warning {
  height: 56px;
  background: #fff9fa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 24px;
}

.apps-wrapper {
  max-height: 313px;
  overflow-y: auto;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    padding: 33px 16px 0;
  }

  .delete-account {
    width: auto;
  }
}
