.bell {
  position: relative;
  width: fit-content;
}

.count {
  position: absolute;
  background: #ff615e;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 5%;
  right: 5%;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip {
  background: #0b1641;
}

.tooltip-arrow {
  color: #0b1641;
}