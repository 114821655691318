.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
  height: fit-content;
}

.button-back {
  padding: 0;
}

.button-back:hover {
  background: none;
}

.input {
  padding-left: 48px !important;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: #4c6ad4;
}

.profile-provider {
  width: 600px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.input {
  padding-left: 48px !important;
}

.provider {
  display: flex;
  align-items: center;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
  margin: 16px 0;
  cursor: pointer;
}

.provider:hover {
  background: #fbfbfc;
}

.icon-button-wrapper {
  padding: 0;
  margin-left: auto;
}

.icon-button:hover path {
  stroke: #0b1641;
}

.submit-buttons {
  margin-left: auto;
  width: fit-content;
  margin-top: 24px;
}

.custom-checkbox-wrapper + .custom-checkbox-wrapper {
  margin-top: 16px;
}

.provider-name-wrapper {
  max-width: calc(100% - 180px);
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: #f1f1f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border: 1px solid #e7e8ec;
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-modal {
  background: #fff;
  border-radius: 8px;
  border: none;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rebind-provider-modal {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.provider-modal-header {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f5f6f8;
  border-radius: 8px;
  padding: 16px 24px 16px 32px;
  height: 90px;
  position: relative;
}

.provider-modal-content {
  font-size: 14px;
  padding: 24px 26px 24px 32px;
}

.provider-icon-modal {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-close-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
}
