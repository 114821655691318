.wrapper {
  position: absolute;
  left: 0;
  padding-left: 24px;
  height: 55px;
  background: white;
  z-index: 1;
  align-items: center;
  display: flex;
}

.users-operation-buttons-wrapper {
  margin-left: 56px;
  display: flex;
}

.users-operation-button {
  white-space: nowrap;
}

.users-operation-button + .users-operation-button {
  margin-left: 16px;
}

.modal-wrapper {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 1px;
  max-width: 500px;
  max-height: 100vh;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar {
  background-color: #f89f33;
  width: 40px;
  height: 40px;
  margin-right: 24px;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: #fff;
}

.apps-to-delete {
  min-height: 67px;
  padding-right: 5px;
  max-height: calc(100vh - 570px);
  overflow-y: auto;
}

.app-to-delete {
  display: flex;
  align-items: center;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  padding: 12px 16px;
}

.app-to-delete + .app-to-delete {
  margin-top: 16px;
}

.app-icon {
  background: #fff;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 32px;
}

.app-link {
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #4c6ad4;
}

.app-link:hover {
  text-decoration: underline;
}

.operations-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
  background: #e7e8ec;
}

.wrapper .operations-icon:hover {
  background: #e7e8ec;
}

.operations-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.popover-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
  transform: translateY(20px) !important;
}

.popover-button-icon {
  margin-right: 16px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popover-button-refresh-icon {
  margin-right: 15px;
  animation: loading 2s linear infinite;
}

.popover-button-refresh-icon path {
  fill: #0b1641;
}

.popover-operation-button + .popover-operation-button {
  margin-left: 0;
  margin-top: 8px;
}

.load-icon-black path {
  fill: #0b1641;
}

.radio-delete-users {
  margin-bottom: 10px;
}

.radio-delete-apps {
  margin-bottom: 24px;
}
