.drawer-paper {
  box-shadow: none;
}

.backdrop {
  background: none;
}

.wrapper {
  background: #fff;
  padding: 27px 0 0 0;
  width: 728px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0 32px;
}

.button-back {
  padding: 0;
  margin-right: 16px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: #4c6ad4;
}

.close-button {
  margin-left: auto;
  padding: 0;
}

.close-button path {
  stroke: #0b1641;
}

.choose-provider{
  height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 32px;
}

.subtitle {
  margin-bottom: 24px;
}

.provider {
  margin-bottom: 16px;
  display: flex;
  background: #fff;
  align-items: center;
  height: 64px;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: #f1f1f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border: 1px solid #e7e8ec;
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-name {
  overflow: hidden;
  text-overflow: ellipsis;
}